@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.mdp-pricing {
  &__title {
    color: $neutral800;
    font-family: "Poppins Medium";
    font-size: 0.875rem;
  }
}

.mdp-table {
  display: flex;
  flex-direction: column;

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem;

    &:nth-child(odd) {
      background-color: $neutral-50;
    }

    &--text {
      font-family: "Poppins Light";
      font-size: 0.875rem;
      color: $black;
      margin: 0;
    }

    &--price {
      font-family: "Poppins Light";
      font-size: 0.875rem;
      color: $neutral400;
      margin: 0;
    }

    &--bold {
      font-family: "Poppins Medium";
      font-size: 0.875rem;
      color: $black;
      margin: 0;
    }
  }
}
