@import "../../styles/partials/mixins";
@import "../../styles/partials/variables";

.profile-settings {
  @include flex-column;
  justify-content: flex-start;
  padding: 34px 3.125vw;
  border-radius: 10px;
  border: solid 1px $secondary300;
  width: 61vw;

  &__editbuttoncontainer {
    @include flex-row;
    justify-content: flex-end;
  }

  &__savebuttoncontainer {
    width: fit-content;
    margin-inline: auto;
  }
}

.edit-btn {
  padding: 20px 40px;
  border-radius: 400px;
  background-color: $yellow200;
  border: none;
  font-family: "Poppins SemiBold";
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: left;
  color: $neutral700;
}

.save-btn {
  padding: 20px 20px;
  border-radius: 400px;
  box-shadow: 0 4px 12px 0 rgba(255, 184, 67, 0.68);
  border: none;
  background-image: linear-gradient(74deg, $yellow500 28%, #ff9d43 96%);
  font-family: "Poppins SemiBold";
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: left;
  color: $neutral700;

  &__container {
    display: flex;
    justify-content: center;
  }

  &__text {
    margin-block-start: 0.5px;
  }
}

@media only screen and (max-width: 1020px) {
  .profile-settings {
    width: 90vw;
    padding-left: 1rem;
    margin-left: 1.25rem;
    margin-right: 1rem;
    align-items: center;
  }
  .edit-btn {
    text-align: center;
    margin-bottom: 1rem;
  }
}
