@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.dimensions {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 1.25rem;
  padding: 1rem 0;

  @media screen and (max-width: 1020px) {
    flex-direction: column;
  }

  &__left {
    display: flex;
    flex: 0 0 auto;
    align-self: stretch;
    box-sizing: border-box;

    &--image {
      height: auto;
      width: 100%;
      flex-shrink: 0;
      object-fit: cover;
      border-radius: 0.9375rem;
    }
  }

  &__right {
    display: flex;
    // width: 12.125rem;
    flex-direction: column;
    align-items: flex-end;
    gap: 1.25rem;
    width: 100%;
    flex: 1 1 auto;
    box-sizing: border-box;
  }
}

.cargo {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: 100%;

  &__title {
    margin: 0;
    // color: $neutral800;
    color: #6c675e;
    font-family: "Poppins Medium";
    font-size: 0.875rem;
  }
}
