@import "../partials/mixins";
@import "../partials/variables";

.button__gradient {
  @include gradient-button();
  width: -webkit-fill-available;
}

.button__plain {
  @include plain-button();
  width: -webkit-fill-available;
}

.button__gradient--bold {
  @include gradient-button(1.25rem, 2.5rem);
  font-family: "Poppins Medium";
  width: max-content;
}

.button__plain--narrow {
  @include plain-button(1.25rem, 2.5rem, #fffefa);
  width: fit-content;
}

.button__plain--yellow {
  @include plain-button(0.5rem, 1rem, $yellow200);
  width: -webkit-fill-available;
  border: none;
  font-family: "Poppins Light";
}

.button__gradient--disabled {
  @include gradient-button();
  width: -webkit-fill-available;
  background: $secondary200;
  box-shadow: none;
}

// .button__plain--bold {
// 	@include gradient-button(1.25rem, 2.5rem, white);
// 	font-family: "Poppins Medium";
// }
