@import "../../styles/partials/variables.scss";

.mdp-page {
  display: flex;
  flex-direction: row;
  z-index: 0;
}

.mdp {
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  padding: 4rem;
  border-top: 11px solid $yellow500;
  background-color: white;
  flex-grow: 1;

  @media screen and (max-width: 1020px) {
    padding: 2rem;
  }
}

.left {
  width: 70%;
}

.right {
  width: 30%;
}

.back-button {
  color: $neutral500;
  text-decoration-line: underline;
  top: 2.6rem;
  font-family: "Poppins Light";
  margin-bottom: 1rem;
}

.back-button-div-mobile {
  display: none;
}

.car-image-mobile {
  display: none;
}

@media screen and (max-width: 1020px) {
  .mdp-page {
    flex-direction: column;
  }

  .mdp {
    flex-direction: column;
  }

  .left {
    width: 100%;
    order: 2;
  }

  .right {
    width: 100%;
    order: 1;
  }

  .back-button-div-mobile {
    display: block;
  }

  .back-button-div-desktop {
    display: none;
  }

  .car-image-mobile {
    display: block;
  }

  .car-image-desktop {
    display: none;
  }
}
