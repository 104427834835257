@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.mdp-card__unselected {
  display: flex;
  padding: 0.5rem 1rem;
  flex-direction: column;
  align-items: center;
  gap: 0.4375rem;
  align-self: stretch;
  border-radius: 0.9375rem;
  background: white;
  border: 4px solid transparent;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.08);

  &:hover {
    cursor: pointer;
    border: 4px solid transparent;
    background: $neutral-50;
  }
}

.mdp-card__selected {
  display: flex;
  padding: 0.5rem 1rem;
  flex-direction: column;
  align-items: center;
  gap: 0.4375rem;
  align-self: stretch;
  border-radius: 0.9375rem;
  border: 4px solid $yellow500;
  background: $yellow100;
}

.title {
  margin: 0;
  color: $neutral500;
  text-align: center;
  font-size: 0.875rem;
  letter-spacing: 0.0125rem;
}

.details {
  margin: 0;
  color: $black;
  font-size: 1.125rem;
  letter-spacing: 0.0125rem;
}
