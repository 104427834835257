@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.mdp-image-container {
  padding-bottom: 2rem;
  width: 100%;
}

.mdp-gallery {
  display: flex;
  width: 80%;
  height: auto;
  padding: 21px 0;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.9375rem;
  border: 1.227px solid var(--foundation-neutrals-100, #efefee);
  background: #fff;

  &__img {
    object-fit: cover;
    flex-shrink: 0;
    width: 100%;
    height: auto;
  }
}
