@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.speed-dating-dashboard {
  @include dashboard-desktop;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 2rem;
  width: 100%;
  box-sizing: border-box;

  @media screen and (max-width: 1024px) {
    margin: 1rem 0 0 0;
  }

  &__header {
    @include flex-column-center;
    margin-bottom: 2rem;
  }

  &__text {
    @include body;
    margin: 0;
  }

  &__main {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85%;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
  }

  &__cards {
    width: 100%;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
  }
}

.options {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;

  &__text {
    margin: 0;
    font-size: 1.25rem;
    text-align: center;

    @media screen and (max-width: 767px) {
      padding-bottom: 1rem;
    }
  }

  &__icon {
    padding-bottom: 1.5rem;
    cursor: pointer;

    @media screen and (max-width: 767px) {
      padding-bottom: 0.5rem;
      width: 75%;
      height: 75%;
    }
  }
}

.like_desktop {
  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.dislike_desktop {
  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.options-row {
  display: none;

  @media screen and (max-width: 1024px) {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 1rem;
    text-align: center;

    @media screen and (max-width: 767px) {
      gap: 0;
    }
  }
}
