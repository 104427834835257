@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.matches-list {
  @include flex-column;
  gap: 2rem;

  &__title {
    font-family: "Poppins Medium";
    padding-left: 2rem;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;
    padding-left: 2rem;
  }

  &__selection {
    margin-block-end: 2rem;
    margin-block-start: 1rem;
  }
}

.Comparison-Button {
  width: 25vw;
}

.padding-hide {
  padding: 0;
}

.grow-space {
  gap: 2.25rem;
}

.selection_status_container {
  @include flex-row;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    gap : 1rem;
    margin: 0.5rem;
  }

  @media screen and (min-width: 768px) and (max-width : 1023px) {
    margin: 1rem;
  }
}

.name-with-bin {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
}



@media only screen and (max-width: 1020px) {
  .matches-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;

    &__wrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 1.25rem;
      padding-left: 0;
    }

    &__selection {
      width: 100%;
      margin-block-end: 0rem;
      margin-block-start: 0rem;
      padding-inline-end: 0rem;
      box-sizing: border-box;
      text-align: center;
    }

    &__title {
      text-align: center;
    }
  }

  .Comparison-Button {
    width: 100%;
    text-align: center;
  }
}
