@import "../../styles/partials/mixins.scss";
@import "../../styles/partials/variables";

.speed-dating__description {
  @include body;
  width: 25.5rem;
  text-align: center;
  color: $neutral600;
  margin-bottom: 2.6875rem;
}

.dashboard-speed-dating {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2.9375rem 0 0 0;

  &__filler {
    &--image {
      width: 28.875rem;
      height: auto;
    }
  }

  &__intro {
    &--button {
      display: flex;
      justify-content: center;
    }
  }
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .dashboard-speed-dating {
    padding: 1rem 1rem 1rem 1rem;
  }

  .speed-dating__description {
    max-width: 20rem;
    margin-bottom: 1rem;
    padding: 1rem 1rem 1rem 1rem;
  }

  .dashboard-speed-dating__intro--button {
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .dashboard-speed-dating__filler--image {
    width: 300px;
    height: auto;
  }
}

.section-header {
  font-size: 1.5rem;
  font-family: "Poppins Medium";
  color: $black;
  margin-bottom: 0;
}

.adjust-profile-btn {
  padding: 20px 40px;
  border-radius: 400px;
  border: solid 1px $black;
  text-align: center;
  font-family: "Poppins SemiBold";
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: left;
  color: $neutral700;
  background-color: white;
}
