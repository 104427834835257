@import "../../styles/partials/_variables.scss";

.sidebar-menu-item {
  display: flex;
  gap: 0.46875rem;
  // padding-bottom: 1.5rem;
  padding: 0.75rem 0;
  align-items: center;

  &__label {
    color: $neutral600;
  }

  &__unviewed {
    width: 11px;
    height: 11px;
    border-radius: 52px;
    background-color: #ff881b;
  }

  &.sidebar-selected {
    border-left: 0.3125rem solid $yellow400;
    margin-left: -1.65rem;

    .sidebar-menu-item__icon {
      stroke: $yellow800;
      fill: $yellow800;
      padding-left: 1.35rem;
    }

    .sidebar-menu-item__label {
      font-family: "Poppins Medium";
      color: $yellow800;
    }
  }
}

.crown-size {
  width: 24px;
  height: 26px;
}
