@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.popup_position {
    position: absolute;
    top: 0.5rem;
    right: 2rem;
  
    @media only screen and (max-width: 767px) {
      right: 0.5rem;
    }
  
    @media only screen and (min-width: 768px) and (max-width: 1080px) {
      right: 0.8rem;
    }
}
  