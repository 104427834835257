@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.dashboard > * {
  box-sizing: border-box;
}

.poweredBy {
  display: flex;
  align-items: center;
}

.dashboard {
  @include dashboard-desktop;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;

  @media (max-width: 1024px) {
    display: block;
    height: auto;
    padding-block: 2rem;
  }

  &__filler {
    @include flex-end;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 0;
    width: 50%;
    overflow: hidden;
    margin: auto;

    @media (max-width: 1024px) {
      width: 100%;
      justify-content: center;
    }

    &::after {
      right: 0;
      bottom: 0;
      pointer-events: none;
      content: "";
      position: absolute;
      width: 30%;
      height: 100%;
      opacity: 0.3;
      -webkit-filter: blur(150px);
      filter: blur(150px);
      background-image: linear-gradient(43deg, $yellow500 22%, #ff9d43 89%);
      z-index: 0;
    }

    &--image {
      height: 80%;
      width: auto;
      flex-shrink: 0;
      z-index: 1;

      @media (min-width: 768px) and (max-width: 1024px) {
        justify-content: center;
        width: 70%;
        height: auto;
      }

      @media (max-width: 768px) {
        display: none;
      }

      @media (min-width: 1024px) and (max-width: 1220px) {
        height: 60%;
      }
    }
  }

  &__intro {
    @include flex-column;
    justify-content: center;
    gap: 1rem;
    width: 50%;
    padding: 0rem 4rem;

    @media (max-width: 1024px) {
      width: fit-content;
      align-items: center;
    }

    &--logo {
      width: 4.78294rem;
      height: 1.73881rem;

      @media (max-width: 1024px) {
        margin-block-end: 1rem;
      }
    
    }

    &--dealership-logo {
      // margin-block-end: 2rem;
      width: 16rem;

      @media (max-width: 1024px) {
        margin-block-end: 1rem;
      }
    }

    &--title {
      @include subheader;
      @media (max-width: 768px) {
        text-align: center;
        font-size: 2rem;
      }
      @media (min-width: 768px) and (max-width: 1024px) {
        text-align: center;
      }
    }

    &--description {
      color: #000;
      font-family: "Poppins Light";
      font-size: 1rem;
      font-style: normal;
      width: 80%;

      @media (max-width: 768px) {
        text-align: center;
        font-size: 1rem;
        margin: 0.5rem 0rem;
        width: 90%;
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        text-align: center;
        font-size: 1.2rem;
        margin: 0.5rem 0rem;
        width: 75%;
      }
    }

    &--buttons {
      width: 75%;
      margin-block: 1rem;

      @media (max-width: 768px) {
        width: 100%;
        margin-top: 0;
        margin-bottom: 2rem;
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        width: 75%;
        margin-top: 0;
        margin-bottom: 2rem;
      }
    }
  }
}
