@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.compatibility-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.12rem;
  margin-top: 1rem;

  &__circle {
    position: relative;
  }

  &__text {
    display: flex;
    flex-direction: column;

    &--row {
      display: flex;
      flex-direction: row;
      gap: 0.25rem;
    }
  }
}

.compatibility-info-stamp {
  display: flex;
  flex-direction: column;
  height: 1.5rem;
  padding: 0.125rem 0.5rem;
  align-items: center;
  width: fit-content;
  position: absolute;
  bottom: 22px;
  left: 3px;

  &__number {
    color: $black;
    font-family: "Poppins Bold";
    font-size: 1.14019rem;
    margin: 0;
  }

  &__text {
    // color: #007004;
    color: $black;
    font-family: "Poppins Light";
    font-size: 0.5rem;
    margin: -9px;
  }

  &__percent {
    font-size: 0.5rem;
  }
}

.compatibility-info-match {
  display: flex;
  flex-direction: column;

  &__row {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
  }

  &__text-normal {
    margin: 0px 0px 10px 0px;
    color: $newNeutral700;
    font-size: 0.75rem;
    font-family: "Poppins Regular";
  }
  
  &__text-bold {
    margin: 0px 0px 10px 0px;
    color: $newNeutral700;
    font-size: 0.875rem;
    font-family: "Poppins Medium";
  }
  
}

@media only screen and (max-width: 792px) {
  .compatibility-info {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__circle svg {
      width: 40px;
      height: 40px;
    }
  }

  .compatibility-info-stamp {
    bottom: 14px;
    left: 1.5px;
    gap: 0.25rem;

    &__number {
      font-size: 0.84019rem;
    }

    &__text {
      font-size: 0.5rem;
    }

    &__percent {
      font-size: 0.6rem;
    }
  }

  .compatibility-info-match{
    align-items: center;
  }
}

