@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}



.chart-layout {
  @include flex-row;
  gap: 1.5rem;
  padding-left: 1rem;

  @media only screen and (max-width: 767px) {
    gap : 0.25rem;
  }
}

.compare-label-layout {
  @include flex-column;
  gap: 2rem;
}

.compare-label-layout > * {
  width: max-content;

  @media only screen and (max-width: 767px) {
    width: 33vw;
  }
}

.chart_car_label {
  @include normal-text-attributes;
  color: $black;
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;

  @media only screen and (max-width: 767px) {
    font-size: 0.875rem;
    text-align: left;
    width: 75%;
  }
}

.add_car_sign {
  width: 100%;
  height: 200px;

  @media only screen and (max-width: 767px) {
    width: min-content;
    height: 100px;
  }
}

.label-text {
  @include normal-text-attributes;
  color: $neutral500;
  font-family: "Poppins Medium";
  @include flex-row;
  align-items: center;
}

.eptype {
  align-items: flex-start;
}

.label-text-yellow-heading {
  @include normal-text-attributes;
  color: $yellow900;
  font-family: "Poppins Medium";
  font-weight: 700;

  @media only screen and (max-width: 767px) {
    font-size: 0.8rem;
  }
}

.label-with-info {
  @include flex-row;
  position: relative;
}


.label-group {
  @include flex-column;
  gap: 2.56rem;
}



.compare-values-layout {
  display: grid;
  row-gap: 2rem;
  grid-auto-flow: row;
  grid-template-columns: max-content;
  grid-template-rows: max-content;
  height: max-content;
  overflow-x: auto;
}

.chart-container > * {
  width: 100%;
  height: fit-content;

  @media only screen and (max-width: 767px) {
    width: calc(97vw/3 - 1.75rem);
    flex : 1;
  }
  @media only screen and (min-width: 768px) and (max-width: 1080px) {
    width: calc(97vw/3 - 3rem);
    flex : 1;
  }
}

.chart-container {
  @include flex-row;
  gap: 1.5rem;
  height: fit-content;

  &__container {
    @include flex-center;
    padding: 0rem 0.5rem;
  }

  @media only screen and (max-width: 767px) {
    gap :  1rem;
  }
}

.recommended-trim-block {
  @include flex-column-center;
  padding: 0.75rem 0.25rem;
  border-radius: 0.5rem 0.25rem 0.25rem 0.5rem;
  background-color: #ffc943;
  color: #383838;

  &--text {
    @include normal-text-attributes;
    color: $black;
    text-align: center;

    @media only screen and (max-width: 767px) {
      font-size: 0.75rem;
    }
  }
}

.chart_value_text {
  @include normal-text-attributes;
  color: $black;
  text-align: center;
  word-wrap: normal;
  @include flex-row;
  align-items: center;
  @media only screen and (max-width: 767px) {
    font-size: 0.75rem;
  }
}

.price-block {
  @include flex-center;
  padding: -0.25rem 0.5rem;
  gap: 0.2rem;

  &--currency {
    font-family: "Poppins Medium";
    font-size: 0.75rem;
  }
}

.img-block {
  text-align: center;
  height: 200px;

  @media only screen and (max-width: 767px) {
    height: 100px;
  }
}
.img-car {
  border-radius: 0.9375rem;
  border: 1px solid $secondary300;
  max-width: 100%;
  height: 198px;

  @media only screen and (max-width: 767px) {
    height: 100px;
  }
}

.chart-feature-inner-flex {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
}

.more-features {
  display: inline-flex;
  box-sizing: border-box;
  padding: 0.25rem 2.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 3.1665rem;
  border: 0.792px solid var(--Foundation-Black, #383838);
  cursor: pointer;
  width: 25vw;
  &__text {
    @include normal-text-attributes;
    color: var(--Foundation-Neutrals-700, #4f4c46);
    margin-block: auto;

    @media only screen and (max-width: 767px) {
      font-size: 0.75rem;
    }
  }

  &__add {
    @include flex-column-center;
  }

  &__add svg {
    width: 1.5rem;
    height: 1.5rem;

    @media only screen and (max-width: 767px) {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  @media only screen and (max-width: 767px) {
    width: 50vw;
    padding: 0.25rem 1.25rem;
    flex : 1;
  }

  @media only screen and (min-width: 768px) and (max-width: 1080px) {
    width: 33vw;
    padding: 0.25rem 2rem;
    flex : 1;
  }


}

.desired-features > * {
  height: 30px;
}

.other-features > * {
  height: 30px;
}

.lifestyle > * {
  height: 30px;
}

.cc-dimensions {
  width: 100%;
  display: block;
}

.drive-type-container > * {
  justify-content: center;
}


.expanded_mobile_width {
  @media only screen and (max-width: 767px) {
    width : 66vw !important;
    flex: 1;
  }
}

