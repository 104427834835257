@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.match-analysis-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 75%;
  margin: 2rem;
}

.match-analysis {
  // display: flex;
  // flex-direction: row;
  margin: 0.375rem;
  // width: 100%;
  flex: 1 0 0;

  &__header {
    @include header;
    color: $black;
  }

  &__table {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1.125rem;
    border-bottom: 1px solid $neutral300;
    flex-wrap: nowrap;

    &:last-child {
      border-bottom: none;
    }
    // padding-right: 0.375rem;
  }
}

@media (max-width: 768px) {
  .match-analysis-wrapper {
    flex-direction: column;
    width: auto;
    margin: 0.5rem;
  }

  .match-analysis {
    margin-right: 0;
    margin-bottom: 2rem;
  }

  .feature__text {
    font-size: 0.9rem;
  }
}

.feature {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  width: 35%;

  &__text {
    font-size: 0.875rem;
    color: $neutral800;
    font-family: "Poppins Medium";
  }
}

.selection {
  flex: 1 0 0;

  &__text {
    border-radius: 8px;
    background-color: rgba(255, 201, 67, 0.2);
    // width: 100%;
    padding: 0.3125rem 0 0.3125rem 0.375rem;
    margin: 0;
  }
}
