.requirement-container {
  display: flex;
  justify-content: center;
  width: 100%;

  @media only screen and (max-width: 767px) {
    flex : 1;
  }

  svg {
    width: 30px;
    height: 30px;
  }
}
