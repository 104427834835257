@import "../../styles/partials/mixins";
@import "../../styles/partials/variables";

.account--page--layout {
  @include flex-row;

  @media screen and (max-width: 1020px) {
    flex-direction: column;
  }
}

.MyProfileLayout {
  @include flex-row;
  justify-content: center;
  margin-block: 4.6vw;
  margin-inline: 5.7vw;
}

.myprofile {
  @include flex-column;
  background-color: white;
  object-fit: contain;

  &__title {
    font-family: "Poppins SemiBold";
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.93;
    letter-spacing: normal;
    margin: 0;
    color: $black;
  }

  &__sel {
    @include flex-row;
    margin-left: 1.25rem;
    margin-top: 1.8125rem;
    gap: 1rem;

    &--header {
      cursor: pointer;
      margin: 0;
      font-family: "Poppins Regular";
      font-size: 1.25rem;
      padding-block-end: 10px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.2px;
      color: $neutral400;
    }
  }
}

.selected-header {
  border-bottom: 2px solid $yellow500;
  color: $black;
}

@media only screen and (max-width: 1020px) {
  .myprofile__title {
    align-items: center;
    margin-left: 2rem;
    width: 90%;
  }
  .myprofile__sel {
    align-items: center;
    width: 80%;
    margin-left: 1.75rem;
    margin-right: 1rem;
    text-align: center;
  }

  .myprofile__sel--header {
    font-size: 1rem;
  }
}
