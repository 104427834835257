@import "../../styles/partials/mixins.scss";

$cardWidth: 11.625rem; // Width of a card
$gap: 0.8rem; // Gap
$maxCardsPerRow: 4; // Max number of cards in a single row

.quiz {
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
  padding: 1rem 3rem;
  @include dashboard-desktop;
  background-color: #fffefa;

  @media (max-width: 1200px) {
    height: 85vh;
    padding: 1rem 1rem;
  }

  @media (max-width: 768px) {
    height: auto;
    padding: 1rem 1rem;
  }

  &__filler {
    @include flex-column;
    justify-content: space-between;
    flex-grow: 1;
    flex-shrink: 0;
  }

  &__intro {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-height: 40%;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    &--titles {
      display: flex;
      flex-direction: column;
      max-width: 100vw;
      @media (max-width: 768px) {
        max-width: 70vw;
        margin: 1rem 0rem;
      }
    }
  }

  &__options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding: 0.8rem;
    border-radius: 0.9375rem;
    background: var(--foundation-secondary-100, #edeae5);
    box-shadow: 0px 0px 41px -1px rgba(97, 97, 97, 0.15);
    width: #{($cardWidth * $maxCardsPerRow) + $gap * ($maxCardsPerRow - 2)};

    @media (max-width: 768px) {
      $maxCardsPerRow: 2; // Override for mobile
      $cardWidth: 10;
      width: #{($cardWidth * $maxCardsPerRow) + $gap * ($maxCardsPerRow - 1)};
    }

    position: relative;
  }

  &__buttons {
    // width: 25%;
    display: flex;
    margin: 0.5rem 0rem;
    @media (max-width: 768px) {
      margin: 1rem 0rem;
    }
  }
}

.header {
  @include header;
  // font-family: "Poppins Medium";
  font-size: 1rem;
  // color: $yellow700;
  // margin: 0;
}

.subheader {
  // font-family: "Poppins Medium";
  // font-size: 1.5rem;
  // color: $black;
  // margin: 0;

  @include subheader;
  font-size: 2.25rem;

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
}

.subtext {
  font-family: "Poppins Light";
  font-size: 1rem;
  color: var(--foundation-black, #383838);
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
}

// .description {
// 	font-family: "Poppins Light";
// 	font-size: 0.8rem;
// }

.budget-title {
  // position: absolute;
  // bottom: 54%;
  font-family: "Poppins Medium";
  color: $black;
  font-size: 1.25rem;
  margin-bottom: -3rem;
}

.badge__logo {
  @media (max-width: 768px) {
    display: none;
  }
}
