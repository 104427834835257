@import "../../styles/partials/mixins";
@import "../../styles/partials/variables";

.accountEdit {
  &__info {
    @include flex-row;
    padding-block-start: 6.3vh;
    padding-block-end: 12.2vh;
    padding-inline: 3.125vw;
    border-radius: 10px;
    border: solid 1px $secondary300;
    gap: 1.5rem;
    width: 61vw;
  }

  &__profile {
    @include flex-column;

    &--img {
      width: 13.75vw;
      height: 13.75vw;
      margin-bottom: 12px;
      object-fit: contain;
      border-radius: 123.1px;
      background-color: $secondary300;
    }

    &--btn {
      text-align: center;
      font-family: "Poppins Bold";
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: 1.2px;
      color: $accent800;
      cursor: pointer;
    }
  }

  &__forms {
    @include flex-column;
    gap: 2.5rem;
    object-fit: contain;

    &--details {
      @include flex-column;
      flex-wrap: nowrap;
    }

    &--outsideinputContainers {
      @include flex-column;
    }

    &--deepinputContainers {
      @include flex-row-space-between;
      gap: 1rem;
    }

    &--insideinputcontainers {
      @include flex-row;
      gap: 1rem;
    }
  }
}

.flex-gap {
  gap: 0.5rem;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.w-60 {
  width: 60%;
}

.label-design {
  font-family: "Poppins Regular";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.2px;
  color: #000;
}

.profile-input {
  padding: 10px 13px;
  border-radius: 5px;
  background-color: $secondary-50;
  border: none;
  font-family: "Poppins Regular";
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.2px;
  color: #000;
  width: 19.5vw;
}

.profile-input:focus {
  outline: none;
  border: 1px solid $secondary-50;
}

.profile-input::placeholder {
  font-family: "Poppins Regular";
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.2px;
  color: #000;
}

.password-container {
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 7px 11px 16px;
  border-radius: 7px;
  border: solid 1px $secondary300;
  background-color: $secondary-50;
}

.password-label {
  font-family: "Poppins Medium";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: $yellow900;
}

.password-label::after {
  color: #e32;
  content: "*";
  display: inline;
}

.password-input {
  border: none;
  background-color: $secondary-50;
}

.password-input:focus {
  outline: none;
  border: none;
  background-color: $secondary-50;
}

.cta-btns {
  padding: 10px 27px;
  border-radius: 64px;
  border: solid 1px $black;
  font-family: "Poppins Regular";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.2px;
  text-align: left;
  color: $neutral700;
  background-color: white;
  margin-top: 0.75rem;
}

.background-color-save {
  background-color: $yellow200;
  border: none;
}

.pass-input-box {
  display: flex;
  justify-content: space-between;
}

.pass-input-box img {
  width: 24px;
  height: 24px;
  background-color: $secondary-50;
}

.popup_position {
  position: absolute;
  top: 1rem;
  right: 4rem;
}

.disabled {
  background-color: #eeeeed;
  color: $neutral300;
  font: "Poppins Regular";
}

.loader-included {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

@media screen and (max-width: 768px) {
  .accountEdit {
    &__info {
      width: 90vw;
      padding: 8vh 3.125vw 16vh;
    }

    &__profile {
      &--img {
        width: 20vw;
        height: 20vw;
      }
    }
  }
}

@media only screen and (max-width: 1020px) {
  .accountEdit {
    &__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .accountEdit__profile {
      margin-bottom: 1rem;
    }

    .accountEdit__forms {
      width: 100%;
    }

    .accountEdit__forms--details {
      margin-bottom: 1rem;
    }

    .accountEdit__forms--insideinputcontainers {
      flex-direction: row;
    }

    .profile-input,
    .password-input {
      width: 100%;
    }

    .cta-btns-group {
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    .cta-btns {
      width: 100%;
      text-align: center;
      padding: 10px 27px;
      border-radius: 64px;
      border: solid 1px $black;
      font-family: "Poppins Regular";
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: 0.2px;
      color: $neutral700;
      background-color: white;
      margin-top: 0.75rem;
    }

    .background-color-save {
      background-color: $yellow200;
      border: none;
    }
  }
}
