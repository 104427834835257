@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";


/* Animation for tooltip fade-in */

@keyframes tooltipFadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}


.tooltip-label-text {
    @include normal-text-attributes;
    @include flex-row;
    align-items: center;
  }

.label-with-info svg {
    max-width: 1rem;
    max-height: 14px;
    margin-inline-start: 0.25rem;
    transform: translateY(25%);
  }


.tooltip {
    @include normal-text-attributes;
    visibility: hidden;
    opacity: 0;
    background-color: $tooltip-grey;
    color: $white;
    text-align: center;
    border-radius: 6px;
    padding: 0.5rem;
    position: absolute;
    z-index: 1;
    bottom: calc(100% + 2px);
    transition: opacity 0.3s;
    font-family: "Poppins Medium";
    font-size: 0.875rem;

    @media only screen and (max-width: 767px) {
        width: 50vw;
        font-size: 0.75rem;
    }
}

.showTooltip {
    visibility : visible !important; 
    animation : tooltipFadeIn 0.2s ease-in;
    animation-fill-mode: forwards;
}
