@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.user-info {
  @include flex-column;

  &__wrapper {
    @include flex-row-center;
    gap: 1.3125rem;
    padding-bottom: 2rem;
  }

  &__avatar {
    width: 3rem;
    height: auto;
    border-radius: 100%;
  }

  &__name {
    font-size: 1rem;
    color: #57544d;
  }

  &__buttons {
    display: flex;
    gap: 1rem;
    padding: 1.5rem 0 2rem 0;
    border-bottom: 1px solid #cbcac8;
  }
}
