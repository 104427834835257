@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.gallery {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  z-index: -1;

  &__hero {
    padding: 2rem;

    &--header {
      @include header;
    }

    &--title {
      @include subheader;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.popup {
  animation: fadeIn 0.5s forwards;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  width: 343px;
  height: 71px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 34px 27px 53px 267px;
  padding: 16px 32px 12px 24px;
  border-radius: 15px;
  box-shadow: 0 0 41px -1px rgba(97, 97, 97, 0.15);
  background-color: var(--foundation-white);
}

.popup.hide {
  animation: fadeOut 0.5s forwards;
}

@media only screen and (max-width: 1020px) {
  .gallery {
    width: 100%;

    &__hero--header {
      text-align: center;
    }
    &__hero--title {
      text-align: center;
    }
  }
}
