@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.add-cars {
  @include flex-column;
  margin: 1rem 3.5rem;
  width: 100%;

  @media screen and (max-width: 1024px) {
    margin : 1rem;
  }

  &__hero {
    text-align: center;
    &--title {
      @include subheader;
    }
  }
}

.close_container {
  position: absolute;
  top: 1.5rem;
  right: 2rem;
}

.close_container svg {
  width: 30px;
  height: 30px;
}

.add-car-compare-btn {
  width: 10vw;
  margin: 1.5rem;
}
