@import "../../styles/partials/mixins";
@import "../../styles/partials/variables";

.updatePopupLayout {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0 0 15px 15px;
  box-shadow: 0 0 41px -1px rgba(97, 97, 97, 0.15);
  background-color: white;
  width: 30vw;

  &__exit {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-left: auto;
    margin-block: 0.5rem;
    margin-right: 0.5rem;
  }

  &__text {
    font-family: "Poppins Regular";
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    margin: 0;
    padding-inline: 1.5rem;
    padding-block-end: 1rem;
  }
}

.loading-bar {
  width: 100%;
  height: 4px;
  background-color: #ddd;
  position: relative;
  overflow: hidden;
}

.loading-bar::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: #e8b73d;
  animation: loading 5s linear;
}

@keyframes loading {
  0% {
    left: -100%;
  }
  100% {
    left: 0%;
  }
}

@media only screen and (max-width: 440px) {
  .updatePopupLayout {
    width: 65vw;

    &__exit {
      width: 16px;
      height: 16px;
    }

    &__text {
      font-size: 10px;
      padding-inline: 1rem;
    }
  }
}

@media only screen and (min-width: 441px) and (max-width: 980px) {
  .updatePopupLayout {
    width: 40vw;

    &__exit {
      width: 18px;
      height: 18px;
    }

    &__text {
      font-size: 12px;
    }
  }
}
