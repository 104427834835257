@import "../../styles/partials/_variables.scss";

.form-group {
  position: relative;
  width: 100%;
}

.form-field {
  border-radius: 7px;
  border: none;
  font-family: "Poppins Light";
  background-color: $neutral100;
  padding: 11px 11px;
  width: -webkit-fill-available;
  height: 1.5rem;
  color: $neutral900;

  &::placeholder {
    padding: 10px 0px;
  }

  &:focus {
    outline: none;
  }
}

.mb-8 {
  margin-block-end: 8px;
}
