@import "../../styles/partials/mixins";
@import "../../styles/partials/variables";

.quiz-box {
  @include flex-column;
  gap: 22px;
  margin-bottom: 2rem;

  &__textbox {
    @include flex-column;
    gap: 4px;
  }

  &__header {
    @include flex-row;
    gap: 6px;
    font-family: "Poppins SemiBold";
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #000;
    margin: 0;
  }

  &__subheader {
    font-family: "Poppins Light";
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.2px;
    color: $neutral500;
    margin: 0;
  }

  &__slider-container {
    @include flex-column;
    gap: 9px;
    margin-bottom: 9px;

    &--header {
      font-family: "Poppins SemiBold";
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.13;
      letter-spacing: normal;
      text-align: left;
      margin: 0;
      color: #000;
    }
  }

  &__option-container {
    @include flex-row;
    row-gap: 1.25rem;
    column-gap: 1rem;
    margin-bottom: 9px;
    flex-wrap: wrap;
  }

  &__feature-container {
    @include flex-row;
    row-gap: 1.25rem;
    column-gap: 1rem;
    margin-bottom: 9px;
    flex-wrap: wrap;
  }

  &__enderline {
    height: 1px;
    width: 5px;
    width: 100%;
    background-color: $secondary300;
  }
}

.option-block {
  @include flex-row;
  gap: 1rem;
  width: 45%;
  padding: 0 12px 0 0;
  border-radius: 12px;
  border: solid 1px $secondary300;
  background-color: white;
  opacity: 0.6;
  display: flex;
  align-items: center;

  &__greyscalecontainer {
    @include flex-column;
    padding: 15px 14px;
    border-radius: 12px;
    background-color: $secondary300;
    justify-content: center;
    display: flex;
    align-items: center;
    &--greyscaleimg {
      width: 30px;
      height: 30px;
    }
  }

  &__container {
    @include flex-row;
    justify-content: space-between;
    flex: auto;
  }

  &__text {
    @include flex-column;
    justify-content: center;
    gap: 2px;

    &--main {
      font-family: "Poppins Medium";
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: $black;
    }

    &--sub {
      font-family: "Poppins Light";
      font-size: 14px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: 0.2px;
      color: $neutral500;
    }
  }
}

.feature-block {
  @include flex-row;
  justify-content: center;
  gap: 1rem;
  padding: 12px 20px;
  border-radius: 12px;
  border: solid 1px $secondary300;
  background-color: white;
  opacity: 0.6;

  &__greyscalecontainer {
    &--greyscaleimg {
      width: 20px;
      height: 20px;
    }
  }

  &__container {
    @include flex-row;
    justify-content: space-between;
    flex: auto;
    object-fit: contain;
  }

  &__textbox {
    @include flex-column;
    object-fit: contain;
  }

  &__text {
    font-family: "Poppins Regular";
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: $black;
  }
}

.pad-space {
  padding-block: 12px;
}

.pad-space-2 {
  padding-block: 18px;
  padding-inline: 12px;
}

.selected {
  border-radius: 15px;
  border: solid 3px $yellow500;
  opacity: 1;
}

.f-selected {
  font-family: "Poppins Medium" !important;
  font-weight: 500 !important;
  border-radius: 12px;
  border: solid 3px $yellow500;
  opacity: 1;
  background-color: #fff3d4;
}

.editable {
  opacity: 1;
}

.slider-box {
  padding-inline: 20px;
  padding-block-start: 2rem;
  padding-block-end: 45px;
  border-radius: 15px;
  background-color: $secondary100;
}

.selected-img {
  width: 30px;
  height: 30px;
  margin-block: auto;
}

@media only screen and (max-width: 1020px) {
  .quiz-box__option-container {
    width: 90%;
    padding: 10px;
    flex-direction: column;
  }
  .edit-btn,
  .save-btn {
    padding: 10px 20px;
    font-size: 14px;
  }

  .option-block {
    width: 100%;
  }
  .option-block.selected {
    width: 100%;
  }

  .option-block__greyscalecontainer {
    width: 48px;
    height: 48px;
    align-items: center;
    justify-content: center;
  }

  .option-block__greyscalecontainer--greyscaleimg {
    width: 24px;
    height: 24px;
  }
}
