@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.match-analysis-container {
  @include flex-column;
  justify-content: flex-start;
  padding: 32px 36px 41px 36px;
  border-radius: 10px;
  border: solid 1px $secondary300;
  width: 61vw;
  gap: 34px;
}

.font-main {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
}

.font-styles-updated-text {
  font-family: "Poppins Regular";
  font-size: 14px;
  line-height: 1.57;
  letter-spacing: 0.2px;
  color: $neutral600;
}

.matchanalysis {
  &__header {
    @include header;
    color: $black;
    font-family: "Poppins SemiBold";
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: normal;
    margin: 0;
    margin-bottom: 1rem;
  }

  &__table {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1.125rem;
    flex-wrap: nowrap;
    padding: 0;
    width: 44vw;
  }
}

.feature {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  width: 30%;

  &__text {
    font-size: 0.875rem;
    color: $secondary800;
    font-family: "Poppins SemiBold";
    font-weight: 600;
    line-height: 1.29;
    letter-spacing: normal;
  }
}

.selection {
  &__texts {
    font-family: "Poppins Regular";
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: 0.2px;
    padding: 0.3125rem 0 0.3125rem 0.375rem;
    margin: 0;
    color: $black;
  }
}

.bg-grey {
  background-color: $secondary-50;
}

.bg-white {
  background-color: white;
}

.mg-bt-end {
  margin-bottom: 24px;
}

@media only screen and (max-width: 1020px) {
  .match-analysis-container {
    align-items: center;
    margin-left: 1.5rem;
    margin-right: 1rem;
  }

  .matchanalysis,
  .matchanalysis.mg-bt-end {
    width: 100%;
  }
  .matchanalysis__table {
    width: 100%;
  }
  .feature,
  .selection {
    width: 50%;
    text-align: left;
  }
}

@media only screen and (max-width: 470px) {
  .match-analysis-container {
    align-items: center;
    margin-left: 1.5rem;
    margin-right: 1rem;
    width: 300px;
  }
}
