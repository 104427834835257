@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  background: #858585;
  width: 100%;
  height: 100vh;

  &__video {
    width: 54.5625rem;
    height: 28rem;
  }

  &__title {
    @include subheader;
    color: $yellow-50;
  }
}
