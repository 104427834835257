@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.blurred {
    filter: blur(2px);
}

.comparechart {
    @include flex-column;
    overflow: hidden;
    padding-block: 4vh;
    gap: 2rem;
    flex: 1;
  
    @media only screen and (max-width: 767px) {
      gap: 1.5rem;
    }
  
    &__title-container {
      @include flex-space-between;
      padding-inline: 1.25rem;
    }

    &__title-container-with-powered {
       @include flex-row;
       gap : 0.75rem;
       padding-inline: 1.25rem;
    }
  
    &__poweredBy {
      display: flex;
      align-items: center;
      gap : 0.25rem;

      &--text {
        @include normal-text-attributes;
        font-weight: 400;
      }
      
      &--logo {
        width: 4.78294rem;
        height: 1.73881rem;
  
        @media (max-width: 1024px) {
          width: 2.5rem;
          height: 0.9rem;
        }     
      }
    }
}

.comparison-chart-title {
    @include normal-text-attributes;
    font-size: 2rem;
    font-weight: 600;
  
    @media only screen and (min-width: 768px) and (max-width : 1023px) {
        font-size: 1.75rem;
    }

    @media only screen and (max-width: 767px) {
      font-size: 1.125rem;
    }
}

.back-button-container {
    @include flex-end;
    cursor: pointer;
    gap: 0.5rem;
    margin-block: auto;
  
    &__text {
      @include normal-text-attributes;
      font-weight: 500;
    }
  
    svg {
      width: 1rem;
      height: 1rem;
      margin-block: auto;
    }
}


.no-scroll {
    overflow: hidden;
  }
  
.dealership-logo {
    width: 16rem;

    @media only screen and (max-width: 767px) {
        width : 12rem;
    }
}

.help-link-container{
    @include flex-space-between;
    @include normal-text-attributes;
    padding-inline: 1rem 2rem;
    color: #ffc943 !important;
    font-weight: bold;

  }
  
  .help-link{
    display: flex;
    align-items: center;
}


/* Scroll Hint Container */
.scroll-hint {
    @include normal-text-attributes;
    font-weight: 600;
    opacity: 0.7;
    color: #000;
    font-size: 1rem;
  }
  
  @keyframes scrollHint {
    0%, 100% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(10px); 
    }
  }
  
  .scroll-hint p {
    animation: scrollHint 1.5s infinite ease-in-out;
    margin: 0;
  }