@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  width: 25%;
  box-sizing: border-box;
}

.sidebar {
  @include flex-column-space-between;
  height: 100vh;
  background: #ffffff;
  box-shadow: 2px 4px 39px rgba(97, 97, 97, 0.15);
  border-radius: 0px 10px 10px 0px;
  padding: 0 3.31rem 0 1.25rem;
  z-index: 1;
  position: sticky;
  top: 0;
  transition: width 0.3s ease;

  &__wrapper {
    @include flex-column;
    font-family: "Poppins Regular";
    color: $neutral700;
    font-size: 1rem;
  }

  &__dealership-logo {
    width: 13rem;
    margin: 3rem 0rem;
  }

  &__empty_padding {
    padding: 4rem;
  }

  &__logo {
    
    width: auto;
    height: auto;
  }

  &--hidden {
    width: 0;
    overflow: hidden;
  }

  &__hamburger-menu {
    display: none;

    &--line {
      display: none;
    }
  }

  &__overlay {
    display: none;
    &--open {
      display: none;
    }

    &--crossicon {
      display: none;
      &---crossline {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 1020px) {
  .sidebar {
    height: 50px;
    width: auto;
    background-color: transparent;
    box-shadow: none;
    padding: 0 1rem;
    box-sizing: border-box;
    width: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    &__accountinfo {
      display: none;
    }

    &__wrapper {
      display: none;
    }

    &__hamburger-menu {
      display: block;
      z-index: 1000;
      cursor: pointer;

      &--line {
        width: 30px;
        height: 3px;
        background-color: #333;
        margin: 5px auto;
        transition:
          transform 0.3s ease,
          opacity 0.3s ease;
        z-index: 3;
        display: block;
      }
    }

    &__overlay {
      display: none;

      &--open {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: $yellow500;
        z-index: 2000;
      }

      &--crossicon {
        font-size: 2rem;
        width: 50%;
        padding-bottom: 2rem;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      &--menulinks {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
