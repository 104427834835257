@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.authenticate-filler {
  @include flex-column-space-between;
  flex-grow: 1;
  margin: 2rem;
  margin-bottom: 1.5rem;

  @media only screen and (max-width: 1024px) {
    display: none;
  }
}

.dashboard-authenticate {
  @include flex-center;
  flex-grow: 1;
  flex-shrink: 0;
  position: relative; 

  &__img {
    width: 100%;
  }

  @media only screen and (max-width: 1024px) {
    display: none;
  }
}

.tick-list-container {
  margin-top: -50%;
  font-size: 18px;
}

.tick-list-heading {
  color: #A14D00;
  margin-bottom: 15px;
  text-align: left;
  font-size: 18px;
}

.tick-list {
  display: flex;
  flex-direction: column;
}

.tick-sentence {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: small;
}

.tickImg {
  margin-right: 8px;
}

.tick-text {
  font-size: 16px;
}

.dashboard-authenticate__imgalt {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 378.18px; 
  height: auto; 
  margin: 0;
  padding: 0;
}

