@import "../../styles/partials/mixins.scss";
@import "../../styles/partials/variables.scss";

.fp-wrapper {
  &__title {
    font-family: "Poppins SemiBold";
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: "normal";
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    color: $black;
  }

  &__description {
    font-family: "Poppins Regular";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.2px;
    color: $black;
    width: 90%;
  }

  &__inputfield {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 0px 11px;
    border-radius: 7px;
    background-color: $neutral100;
    gap: 5px;
    box-sizing: border-box;
  }

  &__sendemailbtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-grow: 0;
    align-items: center;
    gap: 10px;
    padding: 10px 27px;
    border-radius: 64px;
    background-color: $yellow200;
    margin-top: 19px;
    box-sizing: border-box;
  }

  &__login-btn {
    font-family: "Poppins Regular";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.2px;
    color: $neutral700;
  }

  &__reset-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 27px;
    border-radius: 64px;
    box-shadow: 0 4px 12px 0 rgba(255, 184, 67, 0.68);
    background-image: linear-gradient(77deg, $yellow500 29%, #ff9d43 97%);
    margin-top: 19px;
  }
}

.input-placeholder {
  font-family: "Poppins Light";
  font-size: 14px;
  font-weight: 300;
  width: 100%;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.2px;
  margin-block: 12px;
  color: $neutral400;
}

.sendemailbtn-text {
  background: none;
  border: none;
  flex-grow: 0;
  font-family: "Poppins Regular";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.2px;
  color: $neutral700;
}

.input-label {
  font-family: "Poppins Medium";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: $yellow900;
  margin-top: 7px;
}

.input-label::after {
  color: #e32;
  content: "*";
  display: inline;
}

.form-input {
  width: 100%;
  border: none;
  background-color: $neutral100;
  font-family: "Poppins Light";
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.2px;
  text-align: left;
  color: $neutral900;
  margin-bottom: 7px;
}

.form-input:focus {
  outline: none;
  border: none;
  background-color: $neutral100;
}

.password-error {
  font-family: "Poppins Light";
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #cf3d3d;
}

.pass-input {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.red-border {
  border: solid 2px rgba(207, 61, 61, 0.41);
}

.link-to-login-container {
  width: 100%;
}

@media only screen and (max-width: 1020px) {
  .fp-wrapper,
  .fp-wrapper__inputfield,
  .fp-wrapper__title,
  .fp-wrapper__description,
  .pass-input,
  .form-input {
    width: 100%;
    text-align: center;
  }
  .fp-wrapper__sendemailbtn {
    margin-top: 5px;
    align-items: center;
    align-self: center;
    align-content: center;
    text-align: center;
    margin-left: auto;
    width: 100%;
  }

  .login {
    display: flex;
    gap: 1rem;
  }

  .login__text--bold {
    font-size: 0.75rem;
    color: $yellow800;
    font-family: "Poppins Medium";

    @media only screen and (min-width: 481px) and (max-width: 1024px) {
      font-size: 1rem;
    }
  }

  .link-to-login-container {
    text-align: center;
  }
}
