.expand-title {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;

  &__button {
    border: none;
    background: none;
  }

  &__text {
    font-size: 1.125rem;
  }
}
