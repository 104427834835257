@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.car-details-wrapper {
  display: flex;
  flex-direction: column;
  // width: 30%;
  gap: 1.8rem;
}

.car-details {
  display: flex;
  gap: 0.75rem;
  flex-direction: column;

  // padding-bottom: 1.5rem;
  &__header {
    @include subheader;
    line-height: 2.625rem;
  }
}

.car-highlights {
  padding-bottom: 1.25rem;

  &__text {
    font-size: 1.25rem;
    margin: 0;
  }

  &__title {
    font-size: 0.75rem;
    margin: 0;
    color: $neutral500;
  }
}

.car-overview {
  &__text {
    @include body;
    margin: 0;
  }
}

.icon {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  padding: 2px 8px;
  border-radius: 30px;
  background-color: $yellow200;
  color: #a14d00;
  width: fit-content;
  height: 1.125rem;
  margin-top: 0.75rem;

  &__text {
    font-size: 0.75rem;
  }
}

.mdp-title {
  font-family: "Poppins Medium";
  font-size: 0.875rem;
  color: $neutral800;
  margin: 0;
  padding-bottom: 0.75rem;
}

.car-features {
  display: flex;
  flex-direction: column;

  &__card {
    display: flex;
    flex-direction: row;
    gap: 0.94rem;
    padding-bottom: 0.5rem;

    &--text {
      color: $neutral500;
    }
  }
}

.social-buttons {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.search-box {
  width: 16.875rem;
  display: flex;
  margin-block-start: 1rem;
  flex-direction: column;
  padding: 0.875rem;
  border-radius: 0.9375rem;
  background: var(--foundation-white, #fff);
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.16);
  align-items: flex-start;

  @media screen and (max-width: 1020px) {
    display: none;
  }

  &__title {
    margin: 0;
    font-size: 1.125rem;
    flex: 1 0 0;
    padding-bottom: 0.5rem;
  }

  &__text {
    align-self: stretch;
    font-family: "Poppins Light";
    font-size: 0.75rem;
    color: $neutral500;
    padding-bottom: 1rem;
  }

  &__btn {
    width: 100%;
    background: linear-gradient(85.47deg, #ffc943 28.39%, #ff9d43 96.17%);
    border: none;
    box-shadow: 0px 4px 12px 0px #ffb843ad;
    padding: 10px 27px 10px 27px;
    border-radius: 64px;
    text-align: center;
    font-family: "Poppins Regular";
    color: #4f4c46;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.20000000298023224px;
  }

  &__link {
    width: 100%;
  }

  &__input {
    width: 90%;
    display: flex;
    flex-direction: row;
    padding: 0px 11px;
    border-radius: 7px;
    background-color: $neutral100;
    gap: 8px;

    &--placeholder {
      font-family: "Poppins Light";
      font-size: 14px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: 0.2px;
      margin-block: 12px;
      color: $neutral400;
    }
  }

  // &__form {
  // 	display: flex;
  // 	flex-direction: column;
  // 	gap: 0.88rem;
  // }
}

.like-button {
  align-self: flex-end;
  display: flex;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.3125rem;
  border-radius: 4rem;
  border: 1px solid var(--foundation-mae-yellow-400, #ffd469);

  &__liked {
    background: rgba(255, 201, 67, 0.2);
  }

  &__text {
    margin: 0;
  }

  &:hover {
    cursor: pointer;
    border-radius: 4rem;
    border: 1px solid var(--foundation-mae-yellow-500, #ffc943);
    background: rgba(255, 201, 67, 0.2);
  }

  &:active {
    border-radius: 3.67569rem;
    border: 0.919px solid var(--foundation-mae-yellow-500, #ffc943);
    background: rgba(178, 141, 47, 0.2);
  }
}

.gap-1 {
  gap: 0.88rem;
}
