@import "../../styles/partials/_variables.scss";

.hamburger-menu-item {
  display: flex;
  gap: 0.46875rem;
  // padding-bottom: 1.5rem;
  padding: 1rem 0;
  align-items: center;

  &__label {
    color: white;
    font-size: 2rem;
  }
}

.crown-size {
  width: 24px;
  height: 26px;
}
