@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.blur-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(8px); 
    z-index: 999; 
}

.help_popup_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 35vw;
    padding: 0rem 1.5rem 2.125rem 1.5rem;
    border-radius: 0.9375rem;
    background: #fff;
    box-shadow: 0px 0px 41px -1px rgba(97, 97, 97, 0.15);
    z-index: 1000; 

    &__heading {
        @include normal-text-attributes;
        color: var(--Foundation-Black, #383838);
        font-family: "Poppins Semibold";
        font-size: 1.5625rem;
        font-weight: 600;
        text-align: center;
        margin: 0;
        @media only screen and (max-width: 767px)  {
            font-size: 1.2rem;
        }
    }

    &__subheading {
        @include normal-text-attributes;
        color: var(--Foundation-Neutrals-500, #706f6e);
        text-align: center;
        font-size: 1.25rem;
        font-weight: 400;
        margin: 0;
        @media only screen and (max-width: 767px) {
            font-size: 1rem;
        }
    }
  
    @media only screen and (min-width: 768px) and (max-width: 1080px) {
      width: 50vw;
    }

    @media only screen and (max-width : 767px) {
      width: 75vw;
      padding: 0rem 1rem 1.625rem 1rem;
    }
}

.badge_logo_bright {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1010;
}
  
.badge_logo_bright svg {
    width: 7.5rem;
    height: 7.5rem;

    @media only screen and (max-width : 767px) {
        width: 5rem;
        height: 5rem;
    }
}

.help_close_popup_container {
    display: flex;
    justify-content: flex-end;
    margin-block: 1rem 3rem;

    @media only screen and (max-width : 767px) {
        @include flex-row;
        justify-content: flex-end;
        margin-block : 1rem;
        margin-inline: auto;
    }
}

.video-container {
    margin-top: 20px;
    display: flex;
    justify-content: center; 
}

video {
    width: 80%; 
    height: auto;
    max-width: 500px; 
    border-radius: 5px;
}