@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.trim {
  display: flex;
  flex-direction: column;
  padding: 2.31rem 0;
  gap: 1rem;

  &__header {
    display: flex;
    flex-direction: column;

    &--title {
      color: #3e3c37;
      font-size: 0.875rem;
      font-family: "Poppins Medium";
      margin-bottom: 0;
    }

    &--text {
      color: $neutral500;
      font-size: 0.625rem;
      font-family: "Poppins Regular";
      margin-top: 0.25rem;
    }
  }
}

.trim-main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 0.25rem;

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 0.5rem;
    background-color: $neutral100;
    border-radius: 0.5rem 0.25rem 0.25rem 0.5rem;
    flex: 1 0 0;
    position: relative;

    &--title {
      font-size: 1rem;
      font-family: "Poppins Light";
      color: $black;
      margin: 1rem 0 0.38rem 0;
      text-align: center;
    }

    &--price {
      font-size: 0.625rem;
      font-family: "Poppins Medium";
      color: $neutral500;
      margin: 0 0 0.12rem 0;
    }

    &--text {
      font-size: 0.625rem;
      font-family: "Poppins Regular";
      color: $neutral500;
      margin: 0 0 1rem 0;
    }
  }
}

.recommended-icon {
  top: -15px;
  right: 0px;
  width: 30px;
  height: auto;
  position: absolute;
}

.recommendation {
  display: flex;
  flex-direction: row;
  gap: 0.62rem;
  padding: 0.6875rem 0.5625rem;
  align-items: flex-start;

  &__text {
    margin: 0;
    color: $black;
    font-family: "Poppins Light";
  }
}
.recommended-level {
  background-color: $yellow500;
  color: $black;

  &--price {
    color: $black;
  }

  &--text {
    color: $black;
  }
}

.bold {
  font-family: "Poppins Medium";
}
