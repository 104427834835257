@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.selection-car-layout {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.selection-bar {
  display: inline-flex;
  padding: 0.625rem 1rem;
  align-items: center;
  gap: 1rem;
  border-radius: 0.4375rem;
  background: #fff;
  box-shadow:
    0px 0px 41px -1px rgba(97, 97, 97, 0.15),
    0px 2px 8px 0px rgba(0, 0, 0, 0.25);

    @media screen and (max-width: 767px) {
      flex-direction: column;
      padding: 0.25rem 0.4rem;
      gap : 0.25rem;
    }

  &__divider-bar {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }  


  &__checkbox {
    display: flex;
    width: 1.25rem;
    height: 1.25rem;
    padding: 0rem 0.3125rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    border: 1px solid $yellow500;
    background: $yellow500;

    @media screen and (max-width: 767px) {
      width: 1rem;
      height: 1rem;
    }


    &--icon {
      width: 0.625rem;
      height: 0.0625rem;
      flex-shrink: 0;
      border-radius: 1.0625rem;
      background: $yellow900;
    }
  }

  &__num {
    color: $neutral500;
    font-family: "Poppins Regular";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0.0125rem;

    @media screen and (max-width: 767px) {
      font-size: 0.75rem;
    }
  
  }

  &__clear {
    color: $yellow900;
    font-family: "Poppins Regular";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0.0125rem;

    @media screen and (max-width: 767px) {
      font-size: 0.75rem;
    }

  }

  &__section  {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    @media screen and (max-width: 767px) {
      gap : 0.75rem;
      width: 100%;
      text-align: center;
    }
  }
}

.selection-warning {
  font-family: "Poppins Medium";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: $yellow900;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
}

.selection-warning::before {
  color: #e32;
  content: "*";
  display: inline;
}
