@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.page404 {
  @include flex-column-center;
  padding: 4.19rem 1rem;
  width: 100%;

  &__intro {
    @include flex-column-center;
    text-align: center;
    max-width: 600px;

    &--titles {
      margin-bottom: 1rem;
    }
  }

  &__filler {
    @include flex-center;
    flex-grow: 1;
    flex-shrink: 0;

    &--image {
      height: auto;
      width: 90%;
      max-width: 100%;
    }
  }

  &__description {
    @include body;
    max-width: 100%;
    text-align: center;
    font-family: "Poppins Regular";
    padding: 0 1rem;
  }

  &__support {
    font-size: 18px;
    font-family: "Poppins Regular";
    text-align: center;
    margin: 20px 0;

    a {
      font-family: "Poppins Regular";
      color: #2f80ed;
      text-decoration: none;
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .page404 {
      padding: 2rem 1rem;
    }

    .page404__intro {
      max-width: 100%;
    }
  }

  .button__plain--narrow {
    margin-top: 1rem;
  }
}
