@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.car-card {
  width: 13.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  box-sizing: border-box;
  background-color: white;
  border-radius: 0.9375rem;
  padding: 0.88rem;
  margin-left: 0.5rem;
  margin-bottom: 1rem;
  margin-right: 0.5rem;
  border: 1px solid var(--foundation-neutrals-100, #efefee);

  &__selected{
    border-radius: 0.9375rem;
    border: 1px solid $yellow400;
    padding: 0.5rem;
    background: #fff;
    box-shadow:
      0px 0px 9px -1px rgba(97, 97, 97, 0.15),
      0px 0px 0px 4px rgba(255, 201, 67, 0.3);
  }

  &__thumbnail {
    display: flex;
    align-items: center;
    border-radius: 0.9375rem;
    
    background: var(--foundation-white, #fff);
    width: auto;
    padding: 0.875rem;
    position: relative;

    &--img {
      width: 100%;
    }

    &--emptyselection {
      position: absolute;
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 0.25rem;
      border: 1px solid var(--Foundation-Second-Neutral-300, #d7d2c6);
      left: 0.75rem;
      top: 0.75rem;
    }

    &--heart {
      position: absolute;
      width: 1.25rem;
      height: 1.25rem;
      right: 2rem;
      top: 0.5rem;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    &--title {
      font-family: "Poppins Medium";
      font-size: 1.25rem;
      color: $black;
      margin: 0;
    }

    &--price {
      margin: 0;
      display: flex;
      gap: 0.2rem;
      align-items: flex-start;

      &-dollarsign {
        font-size: 0.625rem;
        margin-top: 0;
        font-family: "Poppins Medium";
        color: $neutral500;
      }
      
      &-number {
        font-size: 1rem;
        font-family: "Poppins Medium";
        margin: 0;
        color: $neutral500;
      }
    }

  }

  &__CTA {
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    gap: 1rem;
    align-self: stretch;

    &--text {
      font-family: "Poppins Medium";
      color: $accent800;
      margin: 0;
    }
    &--like {
      border: none;
      background: none;
    }
  }

  &__like-dislike-container {
    display: flex;
    justify-content: center;
    gap: 0.25rem;
  }
  
  &__like-dislike-container svg {
    width: 40px;
    height: 40px;
  }
}

@media only screen and (max-width: 792px) {

  .car-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
    padding-top: 1rem;
    text-align: center;

    &__details {
      padding-top: 2rem;
      width: 100%;
      align-items: center;
    }

    &__thumbnail {
      padding-top: 2rem;
      align-items: center;

      &--emptyselection {
        position: absolute;
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 0.25rem;
        border: 1px solid var(--Foundation-Second-Neutral-300, #d7d2c6);
        left: 0.75rem;
        top: 0.75rem;
      }
    }
  }
}
