@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.speed-dating-card {
  @include flex-column-center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 0.9375rem;
  background: white;
  padding: 1rem 1.6875rem 2.37rem 1.6875rem;
  box-shadow: 0px 0px 41px -1px rgba(97, 97, 97, 0.15);
  width: 450px;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}

.card__image {
  flex-shrink: 1;
  height: auto;

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: auto;
  }

  &--img {
    width: 100%;
    height: 100%;
  }
}

.card__details {
  @include flex-column-center;
  margin-bottom: 1rem;
  color: $black;

  &--bottom {
    @include flex-row-space-between;
    gap: 0.5rem;
  }

  &--text {
    margin: 0;
    font-family: "Poppins Medium";
    font-size: 1.5rem;

    @media screen and (max-width: 767px) {
      font-size: 1.25rem;
    }
  }

  &--label {
    margin: 0;
    font-family: "Poppins Light";
  }
}

.card__box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0.9375rem;
  border: 1px solid var(--foundation-neutrals-300, #b9b8b6);
  padding: 0.5rem;
  align-items: center;
  width: 73%;

  @media screen and (max-width: 1024px) {
    width: 50%;
  }

  &--title {
    margin: 0;
    color: $neutral600;
    font-family: "Poppins Medium";
  }

  &--label {
    margin: 0;
    color: $black;
  }
}
