.App {
  display: flex;
  background: #fffefa;
  background-color: #fffefa;
  font-family: "Poppins Regular";
  margin: -0.5rem;
  padding: 0;
  height: 100%;
}

@media screen and (max-width: 1020px) {
  .App {
    flex-direction: column;
  }
}

a {
  text-decoration: none;
  cursor: pointer;

  &:visited {
    text-decoration: none;
  }
}

button {
  cursor: pointer;
}
