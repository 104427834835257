@import "../../styles/partials/mixins.scss";

.compatibility {
  display: flex;
  justify-content: start;
  gap: 1.25rem;

  &__left {
    width: 50%;
    @media screen and (max-width: 1020px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 1020px) {
    flex-direction: column;
  }
}

.section {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.25rem 0rem;

  &__title {
    color: $neutral800;
    font-size: 0.875rem;
    font-family: "Poppins Medium";
  }

  &__text {
    color: $neutral400;
    font-size: 1rem;
    font-family: "Poppins Light";
  }
}

.number {
  font-family: "Poppins Regular";
}
