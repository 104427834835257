@import "../../styles/partials/mixins.scss";
@import "../../styles/partials/variables.scss";

.forgot-password-page {
  display: flex;
  width: 100vw;
  height: 100vh;
  background-image: url("../../assets/images/MaeBrandPattern.png");
  background-repeat: repeat;
  background-size: contain;
}

.fp-wrapper {
  @include flex-column-center;
  width: 51%;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  box-shadow: 0 0 41px -1px rgba(97, 97, 97, 0.15);
  background-color: white;
  flex-shrink: 1;
  background: #ffffff;
  padding-inline: 8.4375vw;
  padding-block: 19.3vh;
  box-shadow: 0 0 41px -1px rgba(97, 97, 97, 0.15);
  border-radius: 0px 10px 10px 0px;
  box-sizing: border-box;
}

@media (max-width: 1020px) {
  .fp-wrapper{
    width: 100%;
  }
  .fp-wrapper__title{
    text-align: center;
    align-items: center;
    align-self: center;
  }
  .fp-wrapper__description{
    text-align: center;
    align-items: center;
    align-self: center;
  }
  .fp-wrapper__sendemailbtn{
    text-align: center;
    align-items: center;
    width: 100%;
    align-self: center;
  }
}