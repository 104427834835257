@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.banner-container {
  display: flex;
  padding: 2rem;
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background: var(--Foundation-Mae-Yellow-100, #ffeec5);

  &--text {
    color: $normal-black;
    width: 75%;
    font-family: "Poppins Regular";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.326rem;
    letter-spacing: 0.01063rem;

    @media only screen and (max-width: 767px) {
      font-size: 0.75rem;
      width: 100%;
      text-align: center;
  }
}

  &--button {
    @include flex-column-center;
  }

  @media only screen and (max-width: 767px) {
    @include flex-column-center;
    gap : 0.5rem;
    padding: 0.75rem;
  }

  @media only screen and (min-width: 768px) and (max-width: 1080px) {
    padding: 2rem 3rem;
  }
}
