@import "../../styles/partials/mixins.scss";

.card {
  box-sizing: border-box;
  display: flex;
  width: 11.625rem;
  height: auto;
  padding: 0.2rem 1rem;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0.75rem;
  align-self: stretch;
  flex-grow: 0;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 10rem;
  }

  &__unselected {
    background: white;
    border: 5px solid white;
    color: var(--foundation-black, #383838);
  }

  &__selected {
    background: $yellow200;
    border: 5px solid $yellow500;
    // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    // color: #fff;
  }

  &__images {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    &--icon {
      width: 2.75rem;
      padding: 0.5rem 0rem;
    }

    &--check {
      width: 1.5rem;
      padding: 0.5rem 0rem;
    }
  }

  &--text {
    @include body;
    line-height: 1.5rem;
    // font-size: 0.85rem;
    font-family: "Poppins Medium";
    padding: 0.5rem 0rem;
    align-self: stretch;
    flex-grow: 1;
  }

  &--subtext {
    font-size: 0.85rem;
    font-family: "Poppins Light";
    padding: 0.5rem 0rem;
  }
}

.feature-card {
  display: flex;
  width: auto;
  height: 48px;
  // flex: 1 0 auto;
  flex-grow: 0;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding: 0px 20px;

  &__images {
    &--icon {
      width: 19.9px;
      height: 19.5px;
    }
  }
}
