@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.recommendations {
  display: flex;
  flex-direction: column;
  gap: 2.12rem;
  background: linear-gradient(46deg, #ffd469 19.79%, #ff9d43 100%);
  padding: 2rem;
  position: relative;
  z-index: 1;
  box-sizing: border-box;

  &__title {
    @include subheader;
    font-size: 1.875rem;
  }
}

.slick-track {
  display: flex !important; // Makes children align horizontally
  align-items: center;
  width: 5rem !important;
  margin: 0 !important;
}

.slick-slide > div {
  width: 100%;
  padding-right: 1.25rem;
}

.slick-slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 20rem !important; 
}

.slick-list {
  width: 100% !important; 
}

@media only screen and (max-width: 1020px) {
  .recommendations {
    width: 100%;
    &__title {
      text-align: center;
      align-items: center;
    }
  }
}
