@import "../partials/mixins";
@import "../partials/variables";

/*Actual Page Styling*/
.signup-login-page {
  @include flex-grow;
  background-color: $yellow200;
  width: 100vw;
}

.form-wrapper {
  display: flex;
  flex-basis: 50%;
  gap: 20px;

  @media only screen and (max-width: 1024px) {
    flex-basis: 100%;
  }
}

.form {
  @include flex-column-center;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 1;
  padding: 0 6.75rem;
  font-family: "Poppins Regular";
  color: $neutral700;
  font-size: 1rem;
  background: #ffffff;
  box-shadow: 2px 4px 39px rgba(97, 97, 97, 0.15);
  border-radius: 0px 10px 10px 0px;
  height: 100vh;
  gap: 11px;

  &__title {
    color: #000;
    font-family: "Poppins Medium";
    font-size: 20px;
    line-height: 24px;
    margin: 0;

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      font-size: 24px;
    }
  }

  @media only screen and (max-width: 767px) {
    padding: 0 2.5rem;
  }
}

.google-signup {
  display: flex;
  width: 26.625rem;

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
}

.button__plain--yellow {
  @include flex-center;
  gap: 10px;
}

.login {
  display: flex;
  gap: 1rem;

  &__text {
    font-size: 1rem;
    color: $neutral500;

    &--bold {
      font-size: 1rem;
      color: $yellow800;
      font-family: "Poppins Medium";
    }
  }
}

.divider {
  @include flex-center;
  text-align: center;
  width: -webkit-fill-available;
  align-items: flex-start;
  margin-block: 1rem;
  width: 100%;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1rem;
  background: #8c9196;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) calc(50% - 1px),
    #8c9196 calc(50%),
    rgba(0, 0, 0, 0) calc(50% + 1px)
  );

  @media (max-width: 1024px) {
    margin-block: 0.75rem;
  }

  &__text {
    font-size: 0.625rem;
    background: #fff;
    padding: 0 1.5rem;
    color: $neutral600;
  }
}

.form-fields {
  @include flex-column;
  gap: 8px;
  width: 100%;

  &__top {
    @include flex-row-space-between;
    gap: 0.7rem;
    margin-bottom: 8px;

    @media only screen and (max-width: 481px) {
      font-size: 1rem;
      @include flex-column-space-between;
      gap: 16px;
    }
  }
}

.form-fields button {
  margin-top: 1rem;
}

.form-group {
  width: 100%;
}

.forgot-password-section {
  margin: 20px 0 0 0;
  font-family: "Poppins SemiBold";
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: 0.2px;
  text-align: center;
  color: $neutral500;
  cursor: pointer;

  @media only screen and (max-width: 767px) {
    margin-top: 10px;
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    font-size: 1rem;
  }
}

.account-error {
  font-family: "Poppins Light";
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #cf3d3d;
}
